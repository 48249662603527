.aside-menu-off-canvas .dashboard_wrap .card-body {
    background: #28a3d8;
}
.aside-menu-off-canvas .dashboard_wrap .card-body h3 {
    text-align: left;
    color: #fff;
    font-size: 20px;
    text-transform: capitalize;
    margin-bottom: 0 !important;
}
.aside-menu-off-canvas .dashboard_wrap .card-body svg {
    position: absolute;
    right: 13px;
    top: 17px;
    color: #fff;
    font-size: 52px;
}
.aside-menu-off-canvas .dashboard_wrap .card-body small {
    font-size: 29px;
    color: #fff !important;
    line-height: 1;
    text-decoration: none !important;
}
.aside-menu-off-canvas .dashboard_wrap .card a {
    text-decoration: none;
}
.aside-menu-off-canvas .react-bs-table table thead tr th {
    background: #28a3d8;
    color: #fff;
        border-top: none;
    border-bottom: none;
}
.aside-menu-off-canvas .btn {
    font-size: 10px !important;
}
.aside-menu-off-canvas .btn.addbtn {
    background: #28a3d8;
    margin-bottom: 13px;
    color: #fff;
    min-width: 75px;
}
.aside-menu-off-canvas .btn.addbtn:hover{
	color: #fff;
}
.aside-menu-off-canvas .btn.btn-primary {
    background: #28a3d8;
    border-radius: 5px;
    color: #fff;
}
.aside-menu-off-canvas .card-header {
    background: #28a3d8 !important;
}
.aside-menu-off-canvas .card-header strong {
    color: #fff;
}
.aside-menu-off-canvas .modal .modal-dialog {
    max-width: 800px !important;
}
.aside-menu-off-canvas .modal .modal-dialog .modal-header {
    background: #28a3d8;
    color: #fff;
    border: none;
}
.aside-menu-off-canvas .modal .modal-dialog .modal-header button.close {
    color: #fff;
    opacity: 1;
}
.aside-menu-off-canvas .modal .modal-dialog .right-lass {
    display: none;
}
.aside-menu-off-canvas .modal .modal-dialog .bg_media-ww {
    flex: 0 0 100%;
    max-width: 100%;
}
.aside-menu-off-canvas .addmedia .tab-content ul.nav.nav-tabs li.nav-item a {
    background: #f2f2f2;
    border-radius: 0;
}
.aside-menu-off-canvas .addmedia .tab-content ul.nav.nav-tabs li.nav-item a.active.nav-link {
    background: #28a3d8;
    color: #fff;
}
.aside-menu-off-canvas .addmedia .tab-content .tab-content .tab-pane .sect-h-scrl .col-sm-2 {flex: inherit;width: 33.33%;max-width: initial;}
.aside-menu-off-canvas .addmedia .tab-content .tab-content .tab-pane .sect-h-scrl .col-sm-2 .card-body {
    background: #f2f2f2;
}
.aside-menu-off-canvas .addmedia .tab-content .tab-content .tab-pane .sect-h-scrl .col-sm-2 .card-body input.img-radd {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}
.aside-menu-off-canvas .addmedia .tab-content .tab-content .tab-pane .sect-h-scrl .col-sm-2 .card-body .img-box-media {
    position: relative;
    margin-bottom: 10px;
}
.aside-menu-off-canvas .addmedia .tab-content .tab-content .tab-pane .sect-h-scrl .col-sm-2 .card-body input.img-radd + span.check_wrap {
    width: 25px;
    height: 25px;
    position: absolute;
    display: block;
    top: 7px;
    right: 6px;
    background: #28a3d8;
    border-radius: 50%;
    display: none;
}
.aside-menu-off-canvas .addmedia .tab-content .tab-content .tab-pane .sect-h-scrl .col-sm-2 .card-body input.img-radd + span.check_wrap:before {content: '';display: block;width: 17px;height: 7px;border-left: 2px solid #fff;border-bottom: 2px solid #fff;position: absolute;transform: rotate(-45deg);top: 6px;left: 4px;}
.aside-menu-off-canvas .addmedia .tab-content .tab-content .tab-pane .sect-h-scrl .col-sm-2 .card-body .img-box-media img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 150px;
    max-height: 150px;
}
.aside-menu-off-canvas .addmedia .tab-content .tab-content .tab-pane .sect-h-scrl .col-sm-2 .card-body input.img-radd:checked + span.check_wrap {
    display: block;
}
.aside-menu-off-canvas .addmedia .tab-content .tab-content .tab-pane .sect-h-scrl .col-sm-2 .card-body input {margin-top: 2px;}
.aside-menu-off-canvas .addmedia .tab-content .tab-content .tab-pane .de-c {
    text-align: center;
    padding: 20px;
}
.aside-menu-off-canvas .addmedia .tab-content .tab-content .tab-pane .de-c .btn.btn-file input[type="file"] {
    margin-top: 11px;
}
.aside-menu-off-canvas .clincdtailpop {
    padding: 20px;
    width: 100%;
}
.aside-menu-off-canvas .clincdtailpop table.table.table-hover {
    border: 1px solid #c8ced3;
}
.aside-menu-off-canvas .clincdtailpop table tr td:nth-child(2) {
    border-right: 2px solid #c8ced3;
}
.aside-menu-off-canvas .clincdtailpop table tr th {
    border-right: 1px solid #c8ced3;
}
.aside-menu-off-canvas .clincdtailpop table tr td, .aside-menu-off-canvas .clincdtailpop table tr th {
    width: 25%;
}
.aside-menu-off-canvas .pb-0.card-body {
    padding-bottom: 25px !important;
}
.aside-menu-off-canvas .table td .col {
    padding: 0;
}



@media only screen and (max-width: 1199px){
    .aside-menu-off-canvas .dashboard_wrap .col-4 {
    flex: 0 0 50%;
    max-width: 50%;
}
}


@media only screen and (max-width: 991px){
.aside-menu-off-canvas .react-bs-table table {
    min-width: 640px;
}
.aside-menu-off-canvas .react-bs-table {
    overflow-x: auto;
}
.aside-menu-off-canvas .clincdtailpop {
    overflow-x: auto;
}
.aside-menu-off-canvas .modal.show .modal-dialog {
    width: calc(100% - 20px) !important;
}
}


@media only screen and (max-width: 767px){
.aside-menu-off-canvas .dashboard_wrap .card-body h3 {
    font-size: 17px;
}
.aside-menu-off-canvas .dashboard_wrap .card-body svg {
    font-size: 33px;
}
.aside-menu-off-canvas .dashboard_wrap .card-body small {
    font-size: 23px;
}
}


@media only screen and (max-width: 480px){
    .aside-menu-off-canvas .dashboard_wrap .col-4 {
    flex: 0 0 100%;
    max-width: 100%;
}
.aside-menu-off-canvas footer > span {
    width: 100%;
    text-align: center;
}

.aside-menu-off-canvas footer {
    padding: 10px 0 !important;
}
}